@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Playfair+Display:ital,wght@0,500;1,500&display=swap');
:root{
  --accent-color: #e12f2f;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
p:not(:last-child){
  margin-bottom: 1.5em;
}
body{
  font-family: "Montserrat";
}
h1,h2,h3,h4,h5,h6{
  font-family: "Playfair Display";
}
section{
  padding: 100px 0;
}
.light-bg{
  background-color: #f1f1f1;
}
img{
  max-width: 100%;
}
a{
  text-decoration: none;
  color: inherit;
}
.container{
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 10px;
}
.row{
  display: flex;
  flex-wrap: wrap;
}
.col-md-4{
  max-width: 33.33%;
  flex: 33.33%;
}
.col-md-5{
  max-width: 41.67%;
  flex: 41.67%;
}
.col-md-6{
  max-width: 50%;
  flex: 50%;
}
.col-md-12{
  max-width: 100%;
  flex: 100%;
}
.col-md-8{
  max-width: 66.67%;
  flex: 66.67%;
}
header{
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
  position: relative;
}
.align-center{
  align-items: center;
}
.j-sb{
  justify-content: space-between;
}
.j-fe{
  justify-content: flex-end;
}
.text-right{
  text-align: right;
}
.logo img{
  max-height: 55px;
}
.btn{
  min-width: 150px;
  min-height: 40px;
  background: #000;
  padding: 12px 30px;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s ease;
}
.btn.accent{
  background-color: var(--accent-color);
}
.btn:hover{
  background-color: var(--accent-color);
}

.hero-image img{
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
}
.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.hero p{
  margin-bottom: 2rem;
}
.btn {
  min-width: 150px;
  min-height: 40px;
  background: #000;
  padding: 12px 30px;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
}

.heading h3 {
  margin-bottom: 40px;
  font-size: 2rem;
  position: relative;
  display: inline-block;
}

.heading h3:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 3px;
  width: 30%;
  background: var(--accent-color);
}

.heading {
  text-align: center;
}
.gap-20{
  gap: 20px;
}
.row.gap-20 .col-md-4 {
  max-width: calc(33.33% - 20px);
}
.feature-card {
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  padding: 15px 30px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.feature-text h4 {
  font-family: montserrat;
  margin-bottom: 0.3rem;
  font-size: 1.1rem;
}
.feature-text p{
  opacity: 0.8;
}
.feature-image {
  max-width: 27%;
  padding: 15px;
  background: #f1f1f1;
  border-radius: 100px;
}

.hero h1 > span {
  position: relative;
}

.hero h1 > span:before {
  content: "";
  position: absolute;
  bottom: 36%;
  right: -120%;
  height: 3px;
  width: 100%;
  background: var(--accent-color);
}

.hero{
  background: url("./assets/banner1.jpg");
  background-size: cover;
  background-position: center;
}
.hero .col-md-5{
  padding: 40px 30px;
  background: rgb(0 0 0 / 50%);
  color: #fff;
}
.text-center{
  text-align: center;
}
.tour-wrapper img {
  aspect-ratio: 1;
  object-fit: cover;
  display: flex;
  width: 100%;
}
.tour-wrapper {
  background: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s ease;
}
.tour-details {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 20px;
  align-items: center;
}
.tour-title{
  font-size: 1.4rem;
  font-weight: 600;
  
}
.price {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--accent-color);
}
.starting-from{
  display: block;
  font-size: 0.8rem;
  opacity: 0.8;
  margin-bottom: 5px;
}
.mb-40{
  margin-bottom: 40px;
}

.tour-img-wrapper {
  position: relative;
  transition: 0.3s ease;
}

.hover-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 70%);
  transition: 0.3s ease;
}
.tour-wrapper:hover{
  transform: scale(1.01);
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}
.tour-wrapper:hover .hover-btn{
  display: flex;
}

.bottom-pop {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #a0eba0;
  position: relative;
  margin-right: 20px;
}

.avatar:before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #74e074;
  z-index: 99;
  bottom: -4px;
  right: -2px;
  border: 2px solid #fff;
}

.avatar img {
  border-radius: 50%;
}
.bottom-pop button{
  background: none;
  outline: none;
  border: none;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}
.bottom-content h4{
  margin-bottom: 10px;
  font-size: 1.1rem;
}
.bottom-content p{
  font-size: 1.1rem;
}
.bottom-content p a{
  color: var(--accent-color);
  font-weight: 600;
}

.links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.links li {
  display: inline-block;
  margin-right: 20px;
  font-size: 1.1rem;
  transition: 0.3s ease;
}
.links li a{
  transition: 0.3s ease;
}
.links li a:hover{
  color: var(--accent-color);
}
.tel-header{
  margin-left: 20px;
}

footer{
  padding: 100px 0;
  border-top: 3px solid var(--accent-color);
}
.footer-copyright{
  padding: 10px 20px
}
.dark-bg{
  background: #010101;
  color: #fff;
}
.footer-details h5{
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.footer-details h6{
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.footer-details a{
  color: var(--accent-color);
  font-size: 1.2rem;
}
.menu-icon span {
  width: 20px;
  display: block;
  height: 2px;
  background: #000;
  margin-bottom: 3px;
}

.menu-icon span:nth-child(2) {
  width: 15px;
}
.hamburger-menu-wrapper{
  display: none;
}
.mobile-menu{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 20px;
  clip-path: inset(0 0 100% 0);
  transition: 0.3s ease;
  text-align: center;
}
.open-menu  .mobile-menu{
  clip-path: inset(0 0 0 0);
  transition: 0.3s ease;
  z-index: 9;
}
.mobile-menu li {
  list-style: none;
  margin-bottom: 10px;
  font-size: 1.1rem;
}
.footer-details a{
  display: block;
}
.header-top{
  padding: 10px 20px;
  text-align: right;
  font-weight: 600;
  background-color: #010101;
}
.header-top p{
  font-size: 1.1rem;
  color: #fff;
  background: var(--accent-color);
  padding: 10px 20px;
  display: inline;
}


.modal-wrapper, .flight-loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 20px;
  z-index: 9;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal, .loader-wrapper {
  padding: 40px 20px;
  background: #fff;
  height: 100%;
  overflow: auto;
  position: relative;
}
.header-form form {
  padding: 20px;
  background: rgb(0 0 0 / 50%);
  color: #fff;
  position: relative
}
.flight-search-wrapper {
  position: absolute;
  top: -45px;
  left: 0px;
  padding: 10px 20px;
  background: var(--accent-color);
  border-radius: 10px 10px 0 0;
}
form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 10px;
}

.form-group.full-width {
  flex: 100%;
  max-width: 100%;
}

.form-group {
  flex: calc(50% - 5px);
  max-width: calc(50% - 5px);
  position: relative;
}
.flight-search .form-group {
  flex: calc(24.33% - 5px);
  max-width: calc(24.33% - 5px);
  position: relative;
}
.form-group.col-md-6 {
  flex: calc(49% - 5px);
  max-width: calc(49% - 5px);
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input, .form-group.text-area textarea, .form-group select {
  width: 100%;
  padding: 10px 20px;
  background: #f4f4f4;
  border: 1px solid rgb(0 0 0 / 10%);
}

.form-group.text-area {
  max-width: 100%;
  flex: 100%;
}

.close-modal, .close-loader {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.4rem;
  cursor: pointer;
}
.loader-wrapper .row {
  flex-direction: column;
}

.loader-info h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.loader-info {
  text-align: center;
  margin-bottom: 30px;
}

.loader-info h4 {
  font-family: 'Montserrat';
  font-size: 0.8rem;
}

.loader-wrapper img {
  max-width: 120px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.loader-wrapper {
  text-align: center;
  padding: 60px 100px;
}

.contact-info h3 {
  font-family: 'Montserrat';
  margin-bottom: 30px;
}

.contact-info a {
  padding: 10px 20px;
  background: var(--accent-color);
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  border-radius: 25px;
}
.form-group.submit {
  justify-self: center;
  text-align: center;
  max-width: 100%;
}
.form-group.submit a, .form-group.submit button{
  padding: 10px 20px;
  background: var(--accent-color);
  margin-top: 10px;
  display: block;
  color: #fff;
  border: none;
}
.form-group.submit button{
  width: 100%;
  height: 100%;
  padding: 12px 20px;
  cursor: pointer;
}
.form-group.submit input {
  background: var(--accent-color);
  color: #fff;
  cursor: pointer;
}
ul.submenu {
  display: none;
  padding: 10px 15px;
  background: #fff;
  position: absolute;
  z-index: 9;
}

.submenu li {
  display: block;
  text-align: left;
  margin-bottom: 10px;
}

li.dropdown {
  position: relative;
}
li.dropdown:hover .submenu{
  display: block;
}
.flight-search .form-group.radio-wrapper div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flight-search .form-group.radio-wrapper input {
  width: auto;
  margin-right: 10px;
}

.flight-search .form-group.radio-wrapper label {
  width: 100%;
  flex: 1;
  margin: 0;
}

.flight-search .form-group.radio-wrapper {
  flex: 100%;
  max-width: 100%;
  display: flex;
  gap: 10px;
}
@media(max-width: 768px){
  .loader-wrapper{
    padding: 40px 10px;
  }
  .form-group, .form-group.full-width, .flight-search .form-group, .flight-search .form-group.col-md-6{
    max-width: 100%;
    flex: 100%;
  }
  .header-top{
    text-align: center;
  }
  .hero h1{
    font-size: 2rem;
  }
  .col-md-4, .col-md-5, .col-md-6, .col-md-8{
    max-width: 100%;
    flex: 100%;
  }
  .row.gap-20 .col-md-4{
    max-width: 100%;
    flex: 100%;
  }
  .gap-20 {
    row-gap: 20px;
  }
  .footer-logo{
    max-width: 300px;
    margin: 0 auto 30px;
  }
  .footer-details{
    text-align: center;
  }
  header .col-md-3{
    max-width: 33.33%;
    flex: 33.33%;
  }
  header .col-md-9{
    display: none;
  }
  .bottom-pop .col-md-4 {
    position: absolute;
    top: 20px;
    right: 20px;
}
.avatar{
  margin-bottom: 20px;
}
a.btn {
  min-width: auto;
  min-height: auto;
  padding: 7px 12px;
  font-size: 0.8rem;
}
.hamburger-menu-wrapper{
  display: flex;
}
}

.airport-dropdown {
  display: none;
  position: absolute;
  background: #fff;
  width: 100%;
  color: #000;
  padding: 10px 0;
  z-index: 9;
}
.airport-dropdown.show{
  display: block;
}
.airport-list {
  padding: 10px;
  cursor: pointer;
}

.airport-list:hover {
  background: var(--accent-color);
  color: #fff;
}
.enquiry-wrapper, .enquiry{
  height: 100%;
}
.enquiry-wrapper .container{
  height: 100%;
    display: flex;
    flex-direction: column;
}
.enquiry-wrapper .row{
  margin-bottom: 20px;
}
.enquiry-wrapper.modal-bottom {
  margin-top: auto;
}
a.talk-agent {
  padding: 10px 20px;
  background: var(--accent-color);
  color: #fff;
}